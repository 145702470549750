import { createBrowserRouter } from "react-router-dom";
import { AppPage } from "./AppPage"
import { DocPage } from "./DocPage"
import { Layout } from "../ui";



export const pagesList = {
    apps:{
        label: "Apps",
        path: "/apps",
        element: <AppPage />
    },
    
    doc:{
        label: "Manuel utilisateur",
        path: "/docs",
        element: <DocPage />
    },
    
}

export const router = createBrowserRouter([
    {
        
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <AppPage />
            },
            ...Object.values(pagesList)
        ]
    },
])