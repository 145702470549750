import { IconButton, useTheme } from "@mui/material"
import { useContext } from "react";
import { UiContext } from "./UiProvider"
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded'
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded'


export function ColorModeSwitcher() {
    const theme = useTheme()
    const { toggleMode } = useContext(UiContext)
    function click() {
        console.log("click!")
        toggleMode()
    }
    return (
        <IconButton onClick={click} color="primary">
            {theme.palette.mode === 'dark' ? <WbSunnyRoundedIcon  /> : <ModeNightRoundedIcon  />}
        </IconButton>
    )
}