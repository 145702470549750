export const AppDB = {
    emby: {
        nom: "Emby",
        url: "https://emby.gregos.me/",
        icone: "emby.png",
        description: "Regardez des films et séries avec Emby"
    },
    jellyfin: {
        nom: "Jellyfin",
        url: "https://jellyfin.gregos.me/",
        icone: "jellyfin.png",
        description: "Regardez des films et séries avec Jellyfin"
    },
    jellyseerr: {
        nom: "Jellyseerr",
        url: "https://jellyseerr.gregos.me/",
        icone: "jellyseerr.svg",
        description: "Demandez de nouveaux films et séries et éventuellement signalez un problème"
    },
    recover: {
        nom: "Recover",
        url: "https://auth.gregos.me/reset-password/step1",
        icone: "auth.svg",
        description: "Réinitialisez votre mot de passe"
    }
}