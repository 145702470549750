import { Box, List, ListItem } from "@mui/material";

export const DocDB = {
    ouvertParDefaut: 0,
    data: [
        {

            categorie: "A savoir avant de commencer",
            contenu: <>
                <Box>Cachouflix est constitué de quatre applications :
                    <List>
                        <ListItem>Emby et Jellyfin qui vont vous permettre de lire vos films et séries préférés</ListItem>
                        <ListItem>Jellyseerr pour demander de nouveaux films et séries</ListItem>
                        <ListItem>et Recover (si jamais vous avez perdu votre mot de passe).</ListItem>
                    </List>
                    Toutes ces applications utilisent les mêmes couplets d'identifiants et de mots de passe. Changer le mot de passe sur l'une d'elles sera répercuté sur les autres.
                </Box>
                <Box>Si vous n'avez pas votre identifiant ou que vous l'avez oublié, n'hésitez pas à le demander à votre serviteur.</Box>
            </>

        },
        {
            categorie: "Emby",
            icone: "emby.png",
            contenu: <>
                <Box>Emby est une application de streaming. Elle fonctionne depuis votre navigateur web classique ou depuis une application sur smartphone/tablette.</Box>
                <br />
                <Box>Pour Emby, les paramètres de connexion sont les suivants :
                    <List>
                        <ListItem>Cliquez sur <strong>Passer</strong></ListItem>
                        <ListItem><strong>Nom d'hôte</strong> : emby.gregos.me</ListItem>
                        <ListItem><strong>Port</strong> : 80</ListItem>
                    </List>
                </Box>
            </>

        },
        {
            categorie: "Jellyfin",
            icone: "jellyfin.png",
            contenu: <>
                <Box>Jellyfin est une application de streaming. Elle fonctionne depuis votre navigateur web classique ou depuis une application sur smartphone/tablette.</Box>
                <Box>Pour Emby, les paramètres de connexion sont les suivants :
                    <List>
                        <ListItem><strong>Serveur</strong> : https://jellyfin.gregos.me</ListItem>
                    </List>
                </Box>
            </>
        },
        {
            categorie: "Jellyseerr",
            icone: "jellyseerr.svg",
            contenu: <>
            <Box>Jellyseerr est l'interface pour demander de nouveaux films et remonter les problèmes (mauvaise qualité d'un film, difficulté à lire...)</Box>
            </>
        }
    ]
}