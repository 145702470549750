import { Outlet } from "react-router-dom"
import React from "react"
import { TopBar } from "./TopBar"
import { Box, Container } from "@mui/material"


export function Layout() {
    return (
        <>
            <TopBar />


            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                sx={{pt: '60px'}}
            >
                <Container maxWidth="xl">
                    <Outlet />
                </Container>
            </Box>
        </>

    )
}