import {AppDB} from 'db'
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material'




function AppCard({ id }) {
    var app = AppDB[id]
    const icone = require('../db/icons/' + app.icone)
    return (

        <CardActionArea href={app.url} >
            <Card sx={{ display: 'flex'}}>
            <CardMedia
                    component="img"
                    sx={{ height: 120, width: 120, display:  'block', p:2 }}
                    image={icone}
                />
                <CardContent sx={{ flex: 1 }}>
                    <Typography variant="h4">
                        {app.nom}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" sx={{ minHeight: '7  0px' }}>
                        {app.description}
                    </Typography>
                </CardContent>
                
            </Card>
        </CardActionArea>

    )

}

export function AppPage() {
  return (
    <Grid container spacing={2}>
        {Object.keys(AppDB).map((k) => <Grid item key={k} lg={3} md={6} xs={12}> <AppCard id={k}/> </Grid>)}
    </Grid>
  )
}