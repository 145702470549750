import { CssBaseline } from "@mui/material";
import { getTheme } from "db";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import React from "react";

export const UiContext = React.createContext()

export function UiProvider({ children }) {
    

    const [mode, setMode] = React.useState('light')

    window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', event => {
            setMode(event.matches ? "dark" : "light")
            console.log(mode); // "dark" or "light"
        });

    function toggleMode() {
        setMode((mode === 'light' ? 'dark' : 'light'))
    }

    const theme = createTheme(getTheme(mode));



    return (
        <UiContext.Provider value={{ mode, toggleMode }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </UiContext.Provider>
    )
}