import { AppBar, Box, Button, Container, Divider, Drawer, IconButton, MenuItem, Toolbar, Typography } from "@mui/material";
import { pagesList } from "pages";
import { Link } from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import React from "react";
import MenuIcon from '@mui/icons-material/Menu';

import CloseIcon from '@mui/icons-material/Close'


export function TopBar() {

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };
    return (
        <AppBar
            position="fixed"
            sx={{
                boxShadow: 0,
                bgcolor: 'transparent',
                backgroundImage: 'none',
                mt: 1,
            }}
        >
            <Container maxWidth="lg">
                <Toolbar
                    variant="regular"
                    sx={(theme) => ({

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexShrink: 0,
                        borderRadius: '200px',
                        bgcolor:
                            theme.palette.mode === 'light'
                                ? 'rgba(255, 255, 255, 0.4)'
                                : 'rgba(0, 0, 0, 0.4)',
                        backdropFilter: 'blur(24px)',
                        maxHeight: 20,
                        border: '2px solid',
                        borderColor: 'divider',
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                    })}
                >
                    <Box
                        sx={{
                            // mt:"6px",
                            flexGrow: 1,
                            display: 'flex',
                            alignItems: 'center',
                            ml: '-18px',
                            px: 0,
                        }}
                    >
                        <Typography variant="h4" color="text.secondary" sx={{ ml: '10px', mr: '12px', py: '6px', px: '12px', userSelect: 'none' }} >
                            🐰    Cachouflix
                        </Typography>
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                            {Object.entries(pagesList).map(([k, v]) =>

                                <MenuItem
                                    component={Link}
                                    sx={{ py: '6px', px: '12px', minWidth: '50px' }}
                                    to={v.path}
                                    key={k}
                                >
                                    <Typography variant="body1" sx={{ px:'2px', mx: '4px',my: '3px' }}>{v.label}</Typography>
                                </MenuItem>

                            )}
                        </Box>
                    </Box>




                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            gap: 0.5,
                            alignItems: 'center',
                        }}
                    >
                        <ColorModeSwitcher />
                    </Box>
                    <Box sx={{ display: { sm: '', md: 'none' } }}>
                        <Button
                            variant="text"
                            color="primary"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            sx={{ minWidth: '30px', p: '4px' }}
                        >
                            <MenuIcon />
                        </Button>
                        <Drawer
                            anchor="right"
                            open={open}
                            onClose={toggleDrawer(false)}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                flexShrink: 1,
                                display: { mobile: 'block', tablet: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', },

                            }}
                        >
                            <Box
                                sx={{
                                    minWidth: '60dvw',
                                    p: 2,
                                    backgroundColor: 'background.paper',
                                    flexGrow: 1,

                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'end',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Box sx={{
                                        position: "fixed", top: 8, right: 8, fontSize: 24, fontWeight: 'bold', "&:hover": {},

                                    }}
                                    >
                                        <ColorModeSwitcher />
                                        <IconButton onClick={toggleDrawer(false)} color="inherit" ><CloseIcon /></IconButton>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight="90vh"

                                >
                                    <Box
                                        width="100%"

                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Divider sx={{ my: 6 }} />
                                        {Object.entries(pagesList).map(([k, v]) =>

                                            <MenuItem
                                            onClick={toggleDrawer(false)}
                                                component={Link}
                                                sx={{ py: '6px', px: '12px', minWidth: '50px' }}
                                                to={v.path}
                                                key={k}
                                            >
                                                <Typography sx={{ pl: 2 }} >{v.label}</Typography>
                                            </MenuItem>

                                        )}
                                        <Divider sx={{ pt: 6 }} />
                                    </Box>
                                </Box>

                            </Box>
                        </Drawer>
                    </Box>



                </Toolbar>
            </Container>

        </AppBar>
    )
}