import { grey } from "@mui/material/colors"


const getDesignTokens = (mode) => ({
    palette: {
        mode,
    },
    

    typography: {
        fontFamily: 'Roboto',
    }
})

export function getTheme(mode) {
    return {
        ...getDesignTokens(mode),
        components: {
            MuiMenuItem: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        borderRadius: '99px',
                        color: grey[500],
                        fontWeight: 500,
                        ...(theme.palette.mode === 'dark' && {
                            color: grey[300],
                        }),
                    }),
                },
            },

        }
    }
}