import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import {DocDB} from 'db';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function CategArea({ data, defaultExpanded }) {
    const icone = (data.icone && require('../db/icons/' + data.icone))

    return (
        <>
            <Accordion defaultExpanded={defaultExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}

                >
                    <Typography variant='h5'>{data.categorie}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item xs={11}>
                            <Box>
                                    {data.contenu}
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                            {(icone && <><Box
                                component="img"
                                justifyContent="flex-end"
                                sx={{
                                    height: 120,
                                    width: 120,
                                }}
                                src={icone}
                            /></>)}

                        </Grid>
                    </Grid>

                </AccordionDetails>
            </Accordion>
        </>
    )
}

export function DocPage() {

    return (
        <>

            {DocDB.data.map((d, i) =>

                <CategArea data={d} key={i} defaultExpanded={(i) === (DocDB.ouvertParDefaut)} />


            )}
        </>
    )
}