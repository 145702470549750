import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { router } from './pages'
import { UiProvider } from 'ui';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <UiProvider>
      <RouterProvider router={router} />
    </UiProvider>
  </React.StrictMode>
);

